import error from "core/resources/firebase/error";
import { showError, showErrorAxios } from "infra/helpers/alerta";
import { FC } from "react";
import { QueryClient, QueryClientProvider } from "react-query";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      refetchOnMount: "always",
      onError(err: any) {
        if (err.response) {
          showErrorAxios(err);
        } else {
          showError(error[(err as any).code]);
        }
      },
    },
    mutations: {
      onError(err: any) {
        showErrorAxios(err);
      },
    },
  },
});

export const ReactQueryProvider: FC = ({ children }) => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
);
