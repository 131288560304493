import { Text } from "@chakra-ui/layout";
import CardDefault from "ui/components/Cards/CardDefault";

function MenuItem({
  onClick,
  title,
  icon,
}: {
  onClick: (ev: any) => any;
  title: string;
  icon: any;
}) {
  return (
    <CardDefault onClick={onClick}>
      {icon}
      <Text mt="16px" fontSize="18px">
        {title}
      </Text>
    </CardDefault>
  );
}

export default MenuItem;
