import { useCallback, useState } from "react";
import axios from "infra/http";

import { showError, showSuccess } from "infra/helpers/alerta";
import error from "core/resources/firebase/error";
import { useCurrentAuth } from "../auth.store";

export default function useSaveProfile() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const { update } = useCurrentAuth();

  const handleSave = useCallback(
    async (data) => {
      try {
        setIsLoading(true);

        const { data: result } = await axios.put("/v1/auth/current", {
          ...data,
        });

        update(result, true);

        showSuccess().then(() => {
          setIsSuccess(true);
          setTimeout(() => {
            setIsSuccess(false);
          }, 500);
        });
        return data;
      } catch (err) {
        showError(error[(err as any).code]);
      } finally {
        setIsLoading(false);
      }
    },
    [update]
  );

  return {
    handleSave,
    isLoading,
    isSuccess,
  };
}
