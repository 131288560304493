import { ConfigProvider } from "antd";

import "moment/locale/pt-br";
import antPtBR from "antd/lib/locale/pt_BR";

function AntdProvider({ children }: any) {
  return <ConfigProvider locale={antPtBR}>{children}</ConfigProvider>;
}

export default AntdProvider;
