import { useCallback } from "react";
import { useRef } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import create from "zustand";

export interface BreadcrumbItem {
  name: string;
  url?: string;
}

interface BreadcrumbState {
  items: BreadcrumbItem[];
  update: (items: BreadcrumbItem[]) => void;
  reset: () => void;
}

export const useBreadcrumbStore = create<BreadcrumbState>((set) => ({
  items: [] as BreadcrumbItem[],
  update: (items: BreadcrumbItem[]) => {
    set(() => ({ items }));
  },
  reset: () => set(() => ({ items: [] })),
}));

export function useBreadcrumb(data: BreadcrumbItem[]) {
  const { items, reset, update } = useBreadcrumbStore();

  const refItems = useRef(items);

  const updateItems = useCallback(
    (data) => {
      if (refItems.current !== data) {
        refItems.current = data;
        update(data);
      }
    },
    [update, refItems]
  );

  useDeepCompareEffect(() => {
    updateItems(data);

    return () => reset();
  }, [data, updateItems]);

  return { items, update };
}
