import { Text, Image, Flex, Button } from "@chakra-ui/react";
import imgWarning from "assets/images/undraw_warning_cyit.svg";
import { useHistory } from "react-router-dom";

function SemPermissaoPage() {
  const history = useHistory();

  return (
    <Flex minH="80vh" flex={1} justifyContent="center" alignItems="center">
      <Flex flexDir="column" justifyContent="center" alignItems="center">
        <Text fontSize="5xl" color="gray.400">
          Sem permissão!!
        </Text>

        <Image mt="64px" maxW="250px" src={imgWarning} />

        <Button
          mt="64px"
          maxW="250px"
          w="100%"
          size="lg"
          onClick={() => history.push("/")}
        >
          VOLTAR
        </Button>
      </Flex>
    </Flex>
  );
}

export default SemPermissaoPage;
