import { Box } from "@chakra-ui/react";
import {
  Document,
  Page,
  View,
  Text,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
import useBoletoDetalhe from "core/features/boleto/hooks/useBoletoDetalhe";
import colors from "core/resources/theme/colors";
import { useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { useLoading } from "ui/components/GlobalLoading";

// Create styles
const styles = StyleSheet.create({
  header: {
    width: "100%",
    height: 80,
    backgroundColor: "#f2f6f9",
    flexDirection: "row",
    display: "flex",
    padding: "0.5cm 0.8cm",
  },
  body: {
    flexGrow: 1,
    padding: "0.5cm 0.8cm",
    flexDirection: "row",
  },
  row: {
    flexGrow: 1,
    flexDirection: "row",
  },
  text: {
    // fontFamily: "Arial",
  },
  label: {
    // fontFamily: "Arial",
    fontSize: 10,
    fontWeight: "bold",
  },
  labelSm: {
    fontSize: 8,
  },
  labelMd: {
    fontSize: 12,
  },
});

function base64ToArrayBuffer(s: string) {
  let asciiString = atob(s);
  return Buffer.from(
    new Uint8Array([...asciiString].map((char) => char.charCodeAt(0)))
  );
}

// Create Document Component
export default function BoletoPage() {
  const route = useRouteMatch<{ id: string }>();

  const { data, isLoading } = useBoletoDetalhe(route.params.id);

  const [height] = useState(window.innerHeight);

  console.log("data", data);

  useLoading(isLoading);

  if (!data) {
    return null;
  }

  return (
    <Box>
      <PDFViewer width="100%" height={height}>
        <Document title="Taxa condominial">
          <Page size="A4">
            <View style={styles.header}>
              <View style={{ width: "60px", height: 100 }}>
                <Image
                  source={{
                    data: base64ToArrayBuffer(data.logo),
                    format: "jpg",
                  }}
                  style={{ maxHeight: "50px" }}
                />
              </View>
              <View style={{ ...styles.row, paddingLeft: 16 }}>
                <View style={{ width: 150 }}>
                  <View>
                    <Text style={{ ...styles.label, marginBottom: 6 }}>
                      {data.beneficiario.nome}
                    </Text>
                    <Text style={styles.labelSm}>
                      {data.beneficiario.endereco}
                    </Text>
                    <View style={{ height: 2 }} />
                    <Text style={styles.labelSm}>
                      {data.beneficiario.endereco2}
                    </Text>
                  </View>

                  <View style={{ height: 8 }} />

                  <View>
                    <Text style={styles.label}>Pagador</Text>

                    <Text style={{ ...styles.labelSm, marginTop: 4 }}>
                      {data.pagador.nome} {data.pagador.documento}
                    </Text>
                  </View>
                </View>

                <View style={{ width: 200 }}>
                  <Text style={styles.label}>Unidade</Text>
                  <Text style={{ ...styles.labelSm }}>
                    {data.pagador.unidade}
                  </Text>
                </View>

                <View style={{ ...styles.row }}>
                  <View
                    style={{
                      marginRight: 8,
                      backgroundColor: "white",
                      height: "30px",
                      borderRadius: 6,
                      padding: 4,
                      textAlign: "right",
                    }}
                  >
                    <Text style={{ ...styles.labelSm, paddingLeft: 8 }}>
                      Vencimento
                    </Text>
                    <Text
                      style={{
                        ...styles.label,
                        fontWeight: "bold",
                        marginTop: 2,
                      }}
                    >
                      {data.dataVencimento}
                    </Text>
                  </View>

                  <View
                    style={{
                      backgroundColor: "white",
                      height: "30px",
                      borderRadius: 6,
                      padding: 4,
                      textAlign: "right",
                    }}
                  >
                    <Text
                      style={{
                        ...styles.labelSm,
                        paddingLeft: 22,
                      }}
                    >
                      Total
                    </Text>
                    <Text
                      style={{
                        ...styles.label,
                        textAlign: "right",
                        marginTop: 2,
                      }}
                    >
                      {data.total}
                    </Text>
                  </View>
                </View>
              </View>
              <View style={{ display: "flex" }}></View>
            </View>
            <View style={styles.body}>
              <View style={{ flex: 1 }}>
                <View style={{ marginBottom: 32 }}>
                  <Text style={{ color: colors.primary, ...styles.label }}>
                    Olá, {data.pagador.nome}
                  </Text>
                  <Text style={{ ...styles.labelSm, marginTop: 4 }}>
                    Sua taxa condominial chegou!
                  </Text>
                </View>

                <View>
                  <Text
                    style={{
                      color: colors.primary,
                      borderBottom: `1px solid ${colors.primary}`,
                      ...styles.label,
                    }}
                  >
                    Composição da cobrança
                  </Text>

                  <View>
                    {data.composicaoCobranca.map((item, index) => (
                      <View
                        key={index}
                        style={{
                          ...styles.row,
                          padding: "4px 0px",
                          backgroundColor: index % 2 ? "#efefef" : "white",
                        }}
                      >
                        <View style={{ flexGrow: 1, ...styles.labelSm }}>
                          <Text>{item.nome}</Text>
                        </View>
                        <View style={{ ...styles.labelSm }}>
                          <Text>{item.valor}</Text>
                        </View>
                      </View>
                    ))}

                    <View
                      style={{
                        ...styles.row,
                        padding: "4px 0px",
                        borderBottom: "1px solid #333",
                        backgroundColor:
                          data.composicaoCobranca.length % 2
                            ? "#efefef"
                            : "white",
                      }}
                    >
                      <View style={{ flexGrow: 1, ...styles.labelSm }}>
                        <Text>TOTAL</Text>
                      </View>
                      <View style={{ ...styles.labelSm }}>
                        <Text>{data.total}</Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>

              <View style={{ flex: 1 }}></View>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </Box>
  );
}
