import { MdClear } from "react-icons/md";
import { Flex, Text } from "@chakra-ui/layout";
import colors from "core/resources/theme/colors";
import OutlineButton from "ui/components/Button/OutlineButton";

export interface ActionBottomType {
  onClick: () => any;
  disableMultiple?: boolean;
  title: string;
  icon?: any;
  color?: string;
  permissions?: string[];
}

function BottomTableFixed<T = any>({
  selectedRows,
  onClose,
  actions,
}: {
  selectedRows: T[];
  onClose: (v: any) => void;
  actions: ActionBottomType[];
}) {
  return (
    <Flex
      minW="324px"
      w="fit-content"
      h="fit-content"
      bg="white"
      rounded={40}
      mx="auto"
      bottom="5vh"
      right={0}
      left={0}
      position="fixed"
      px={8}
      py={4}
      shadow="md"
    >
      <Flex align="center" justifyContent="space-between" gridGap={12}>
        <Flex alignItems="center">
          <Flex align="center" onClick={() => onClose(0)} cursor="pointer">
            <MdClear size={20} color={colors.gray} />
          </Flex>

          <Flex
            minW="20px"
            h="20px"
            rounded="full"
            bg={colors.blue}
            align="center"
            color="white"
            mx={2}
            alignItems="center"
            justifyContent="center"
          >
            {selectedRows.length}
          </Flex>

          <Text>
            {selectedRows.length === 1
              ? "item selecionado"
              : "itens selecionados"}
          </Text>
        </Flex>
        <Flex justifyContent="center" gridGap={4}>
          {actions.map((item, key) => (
            <OutlineButton
              key={key}
              onClick={() => item.onClick()}
              color={item.color}
              permissions={item.permissions}
              _disabled={{
                opacity: 0.3,
              }}
              _hover={{
                opacity: item.disableMultiple && selectedRows.length > 1 ? 0.3: 1,
              }}
              isDisabled={item.disableMultiple && selectedRows.length > 1}
            >
              {item.icon}
              {item.title}
            </OutlineButton>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
}

export default BottomTableFixed;
