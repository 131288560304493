import { useState } from "react";

import { Flex } from "@chakra-ui/layout";
import { Menu } from "core/features/menu/typings/menu";

import HeaderMenuItem from "./HeaderMenuItem";

function HeaderMenu({ menus }: { menus: Menu[] }) {
  const [indexOpen, setIndexOpen] = useState<number | null>(null);

  return (
    <Flex gridGap="16px" mr="auto" ml="48px">
      {menus.map((item, key) => (
        <HeaderMenuItem
          submenuOpen={indexOpen === key}
          onCloseSubmenu={() => setIndexOpen(null)}
          onOpenSubmenu={() => setIndexOpen(key)}
          key={key}
          item={item}
        />
      ))}
    </Flex>
  );
}

export default HeaderMenu;
