import { Switch, Route } from "react-router-dom";

import SemPermissao from "./SemPermissao";

function ErrorsPages() {
  return (
    <Switch>
      <Route path="/errors/sem-permissao" component={SemPermissao} />
    </Switch>
  );
}

export default ErrorsPages;
