const isLocalHost = window.location.hostname.includes("localhost");
const isEmptyEnv = !process.env.REACT_APP_BASE_URL_API?.trim();

const host = window.location.host;

const stagingApi = 'https://api.octopusci.dev.br'

const productionApi = 'https://api.octopusic.com.br'

const isStaging = host.includes('staging');

const url =
  isEmptyEnv && isLocalHost
    ? "http://localhost:2000"
    : isStaging ? stagingApi : productionApi;

const api = process.env.REACT_APP_BASE_URL_API?.trim() || url;

const config = {
  isDev: !process.env.NODE_ENV || process.env.NODE_ENV === "development",
  urlApi: api,
  firebase: {
    apiKey: "AIzaSyD7fCy7J85gj8QJQPaGZffS5hQwymBmTsQ",
    projectId: "octopus-app-d3d22",
    authDomain: "octopus-app-d3d22.firebaseapp.com",
    storageBucket: "octopus-app-d3d22.appspot.com",
    messagingSenderId: "961312071873",
  },
  mixpanel: {
    key: "2d8fee17f6ab3b05e9ec2e9db2668b14",
  },
  hotjar: 2937997,
};

export default config;
