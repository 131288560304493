export const PermissaoEnum = {
  Backoffice: {
    DadosCondominio: {
      Gerenciar: 'Backoffice.DadosCondominio.Gerenciar',
    },
    Admin: {
      Clientes: {
        Visualizar: 'Backoffice.Admin.Clientes.Visualizar',
        Criar: 'Backoffice.Admin.Clientes.Criar',
        Editar: 'Backoffice.Admin.Clientes.Editar',
        Excluir: 'Backoffice.Admin.Clientes.Excluir',
        MudarStatus: 'Backoffice.Admin.Clientes.MudarStatus',
      },
      Parceiro: {
        Visualizar: 'Backoffice.Admin.Parceiro.Visualizar',
        Criar: 'Backoffice.Admin.Parceiro.Criar',
        Editar: 'Backoffice.Admin.Parceiro.Editar',
        Excluir: 'Backoffice.Admin.Parceiro.Excluir',
      },
    },
    PerfilEmpresa: {
      Editar: 'Backoffice.PerfilEmpresa.Editar',
      Assinatura: {
        toString: () => 'Backoffice.PerfilEmpresa.Assinatura',
        AlterarPlano: 'Backoffice.PerfilEmpresa.Assinatura.AlterarPlano',
        CriarMeioPagamento:
          'Backoffice.PerfilEmpresa.Assinatura.CriarMeioPagamento',
        ExcluirMeioPagamento:
          'Backoffice.PerfilEmpresa.Assinatura.ExcluirMeioPagamento',
        AlterarMeioPagamentoPadrao:
          'Backoffice.PerfilEmpresa.Assinatura.AlterarMeioPagamentoPadrao',
      },
    },
    Condominios: {
      Visualizar: 'Backoffice.Condominios.Visualizar',
      Criar: 'Backoffice.Condominios.Criar',
      Editar: 'Backoffice.Condominios.Editar',
      MudarStatus: 'Backoffice.Condominios.MudarStatus',
    },
    Financeiro: {
      Transferencia: {
        Visualizar: 'Backoffice.Financeiro.Transferencia.Visualizar',
        Criar: 'Backoffice.Financeiro.Transferencia.Criar',
        Excluir: 'Backoffice.Financeiro.Transferencia.Excluir',
      },
      Planejamento: {
        PrevisaoOrcamentaria:
          'Backoffice.Financeiro.Planejamento.PrevisaoOrcamentaria',
        ExecucaoOrcamentaria:
          'Backoffice.Financeiro.Planejamento.ExecucaoOrcamentaria',
      },
      MovimentacaoBancaria: 'Backoffice.Financeiro.MovimentacaoBancaria',
      Dashboard: 'Backoffice.Financeiro.Dashboard',
      ContasReceber: {
        toString: () => 'Backoffice.Financeiro.ContasReceber',
        Visualizar: 'Backoffice.Financeiro.ContasReceber.Visualizar',
        Criar: 'Backoffice.Financeiro.ContasReceber.Criar',
        Editar: 'Backoffice.Financeiro.ContasReceber.Editar',
        Excluir: 'Backoffice.Financeiro.ContasReceber.Excluir',
        Liquidar: 'Backoffice.Financeiro.ContasReceber.Liquidar',
      },
      ContasPagar: {
        toString: () => 'Backoffice.Financeiro.ContasPagar',
        Visualizar: 'Backoffice.Financeiro.ContasPagar.Visualizar',
        Criar: 'Backoffice.Financeiro.ContasPagar.Criar',
        Editar: 'Backoffice.Financeiro.ContasPagar.Editar',
        Excluir: 'Backoffice.Financeiro.ContasPagar.Excluir',
        Liquidar: 'Backoffice.Financeiro.ContasPagar.Liquidar',
      },
      Arrecadacao: {
        toString: () => 'Backoffice.Financeiro.Arrecadacao',
        Visualizar: 'Backoffice.Financeiro.Arrecadacao',
        Criar: 'Backoffice.Financeiro.Arrecadacao.Criar',
        Editar: 'Backoffice.Financeiro.Arrecadacao.Editar',
        Excluir: 'Backoffice.Financeiro.Arrecadacao.Excluir',
        Gerar: 'Backoffice.Financeiro.Arrecadacao.Gerar',
      },
      RetornoBancario: {
        toString: () => 'Backoffice.Financeiro.RetornoBancario',
        Visualizar: 'Backoffice.Financeiro.RetornoBancario',
        ProcessarArquivo:
          'Backoffice.Financeiro.RetornoBancario.ProcessarArquivo',
      },
      Cobrancas: {
        toString: () => 'Backoffice.Financeiro.Cobrancas',
        Visualizar: 'Backoffice.Financeiro.Cobrancas.Visualizar',
        AgendarContato: 'Backoffice.Financeiro.Cobrancas.AgendarContato',
        RegistrarCobranca: 'Backoffice.Financeiro.Cobrancas.RegistrarCobranca',
      },
      Acordo: {
        toString: () => 'Backoffice.Financeiro',
        Visualizar: 'Backoffice.Financeiro.Acordo.Visualizar',
        Criar: 'Backoffice.Financeiro.Acordo.Criar',
        Cancelar: 'Backoffice.Financeiro.Acordo.Cancelar',
      },
    },
    Relatorios: {
      toString: () => 'Backoffice.Relatorios',
      Email: {
        Visualizar: 'Backoffice.Relatorios.Email.Visualizar',
        toString: () => 'Backoffice.Relatorios.Email',
      },
      Financeiro: {
        toString: () => 'Backoffice.Relatorios.Financeiro',
        DemonstrativoReceitaDespesa:
          'Backoffice.Relatorios.Financeiro.DemonstrativoReceitaDespesa',
        TotalDespesas: 'Backoffice.Relatorios.Financeiro.TotalDespesas',
      },
    },
    Administrativo: {
      Unidade: {
        toString: () => 'Backoffice.Administrativo.Unidade',
        Visualizar: 'Backoffice.Administrativo.Unidade.Visualizar',
        Criar: 'Backoffice.Administrativo.Unidade.Criar',
        Editar: 'Backoffice.Administrativo.Unidade.Editar',
        Excluir: 'Backoffice.Administrativo.Unidade.Excluir',
        Animal: {
          toString: () => 'Backoffice.Administrativo.Unidade.Animal',
          Visualizar: 'Backoffice.Administrativo.Unidade.Animal.Visualizar',
          Criar: 'Backoffice.Administrativo.Unidade.Animal.Criar',
          Editar: 'Backoffice.Administrativo.Unidade.Animal.Editar',
          Excluir: 'Backoffice.Administrativo.Unidade.Animal.Excluir',
        },
        Veiculo: {
          toString: () => 'Backoffice.Administrativo.Unidade.Veiculo',
          Visualizar: 'Backoffice.Administrativo.Unidade.Veiculo.Visualizar',
          Criar: 'Backoffice.Administrativo.Unidade.Veiculo.Criar',
          Editar: 'Backoffice.Administrativo.Unidade.Veiculo.Editar',
          Excluir: 'Backoffice.Administrativo.Unidade.Veiculo.Excluir',
        },
      },
      Usuario: {
        toString: () => 'Backoffice.Administrativo.Usuario',
        Visualizar: 'Backoffice.Administrativo.Usuario.Visualizar',
        Criar: 'Backoffice.Administrativo.Usuario.Criar',
        Editar: 'Backoffice.Administrativo.Usuario.Editar',
        Excluir: 'Backoffice.Administrativo.Usuario.Excluir',
        Ativar: 'Backoffice.Administrativo.Usuario.Ativar',
        Desativar: 'Backoffice.Administrativo.Usuario.Desativar',
      },
      Reserva: {
        toString: () => 'Backoffice.Administrativo.Reserva',
        Visualizar: 'Backoffice.Administrativo.Reserva.Visualizar',
        Criar: 'Backoffice.Administrativo.Reserva.Criar',
        Aprovar: 'Backoffice.Administrativo.Reserva.Aprovar',
        Cancelar: 'Backoffice.Administrativo.Reserva.Cancelar',
      },
      AreaComum: {
        toString: () => 'Backoffice.Administrativo.AreaComum',
        Visualizar: 'Backoffice.Administrativo.AreaComum.Visualizar',
        Criar: 'Backoffice.Administrativo.AreaComum.Criar',
        Editar: 'Backoffice.Administrativo.AreaComum.Editar',
        Excluir: 'Backoffice.Administrativo.AreaComum.Excluir',
        Ativar: 'Backoffice.Administrativo.AreaComum.Ativar',
        Desativar: 'Backoffice.Administrativo.AreaComum.Desativar',
      },
      Votacao: {
        toString: () => 'Backoffice.Administrativo.Votacao',
        Visualizar: 'Backoffice.Administrativo.Votacao.Visualizar',
        Criar: 'Backoffice.Administrativo.Votacao.Criar',
        Editar: 'Backoffice.Administrativo.Votacao.Editar',
        Excluir: 'Backoffice.Administrativo.Votacao.Excluir',
        Resultado: 'Backoffice.Administrativo.Votacao.Resultado',
      },
      Portaria: {
        toString: () => 'Backoffice.Administrativo.Portaria',
        Gerenciar: 'Backoffice.Administrativo.Portaria.Gerenciar',
      },
      Anuncio: {
        toString: () => 'Backoffice.Administrativo.Anuncio',
        Visualizar: 'Backoffice.Administrativo.Anuncio.Visualizar',
        Aprovar: 'Backoffice.Administrativo.Anuncio.Aprovar',
        Rejeitar: 'Backoffice.Administrativo.Anuncio.Rejeitar',
      },
      Mural: {
        toString: () => 'Backoffice.Administrativo.Mural',
        Visualizar: 'Backoffice.Administrativo.Mural.Visualizar',
        Criar: 'Backoffice.Administrativo.Mural.Criar',
        Editar: 'Backoffice.Administrativo.Mural.Editar',
        Excluir: 'Backoffice.Administrativo.Mural.Excluir',
        Ativar: 'Backoffice.Administrativo.Mural.Ativar',
        Desativar: 'Backoffice.Administrativo.Mural.Desativar',
      },
      Mensagem: {
        toString: () => 'Backoffice.Administrativo.Mensagem',
        Visualizar: 'Backoffice.Administrativo.Mensagem.Visualizar',
        Criar: 'Backoffice.Administrativo.Mensagem.Criar',
        Responder: 'Backoffice.Administrativo.Mensagem.Responder',
        Excluir: 'Backoffice.Administrativo.Mensagem.Excluir',
      },
      SolicitacaoCadastro: {
        toString: () => 'Backoffice.Administrativo.SolicitacaoCadastro',
        Visualizar: 'Backoffice.Administrativo.SolicitacaoCadastro.Visualizar',
        Aprovar: 'Backoffice.Administrativo.SolicitacaoCadastro.Aprovar',
        Rejeitar: 'Backoffice.Administrativo.SolicitacaoCadastro.Rejeitar',
      },
      Documento: {
        toString: () => 'Backoffice.Administrativo.Documento',
        Visualizar: 'Backoffice.Administrativo.Documento.Visualizar',
        Criar: 'Backoffice.Administrativo.Documento.Criar',
        Editar: 'Backoffice.Administrativo.Documento.Editar',
        Excluir: 'Backoffice.Administrativo.Documento.Excluir',
      },
      GrupoUnidade: {
        toString: () => 'Backoffice.Administrativo.GrupoUnidade',
        Visualizar: 'Backoffice.Administrativo.GrupoUnidade.Visualizar',
        Criar: 'Backoffice.Administrativo.GrupoUnidade.Criar',
        Editar: 'Backoffice.Administrativo.GrupoUnidade.Editar',
        Excluir: 'Backoffice.Administrativo.GrupoUnidade.Excluir',
      },
      Fornecedor: {
        toString: () => 'Backoffice.Administrativo.Fornecedor',
        Visualizar: 'Backoffice.Administrativo.Fornecedor.Visualizar',
        Criar: 'Backoffice.Administrativo.Fornecedor.Criar',
        Editar: 'Backoffice.Administrativo.Fornecedor.Editar',
        Excluir: 'Backoffice.Administrativo.Fornecedor.Excluir',
      },
      Perfil: {
        toString: () => 'Backoffice.Administrativo.Perfil',
        Visualizar: 'Backoffice.Administrativo.Perfil.Visualizar',
        Criar: 'Backoffice.Administrativo.Perfil.Criar',
        Editar: 'Backoffice.Administrativo.Perfil.Editar',
        Excluir: 'Backoffice.Administrativo.Perfil.Excluir',
        Ativar: 'Backoffice.Administrativo.Perfil.Ativar',
        Desativar: 'Backoffice.Administrativo.Perfil.Desativar',
      },
      Encomenda: {
        toString: () => 'Backoffice.Administrativo.Encomenda',
        Criar: 'Backoffice.Administrativo.Encomenda.Criar',
        Visualizar: 'Backoffice.Administrativo.Encomenda.Visualizar',
        Atualizar: 'Backoffice.Administrativo.Encomenda.Atualizar',
        Excluir: 'Backoffice.Administrativo.Encomenda.Excluir',
      }
    },
  },
  App: {
    Animal: {
      toString: () => 'App.Animal',
      Visualizar: 'App.Animal.Visualizar',
      Criar: 'App.Animal.Criar',
      Editar: 'App.Animal.Editar',
      Excluir: 'App.Animal.Excluir',
    },
    Veiculo: {
      toString: () => 'App.Veiculo',
      Visualizar: 'App.Veiculo.Visualizar',
      Criar: 'App.Veiculo.Criar',
      Editar: 'App.Veiculo.Editar',
      Excluir: 'App.Veiculo.Excluir',
    },
    Mensagem: {
      toString: () => 'App.Mensagem',
      Visualizar: 'App.Mensagem.Visualizar',
      Criar: 'App.Mensagem.Criar',
      Responder: 'App.Mensagem.Responder',
      Excluir: 'App.Mensagem.Excluir',
    },
    Boleto: {
      toString: () => 'App.Boleto',
      Visualizar: 'App.Boleto.Visualizar',
      Pagar: 'App.Boleto.Pagar',
    },
    Documento: {
      toString: () => 'App.Documento',
      Visualizar: 'App.Documento.Visualizar',
      Criar: 'App.Documento.Criar',
      Editar: 'App.Documento.Editar',
      Excluir: 'App.Documento.Excluir',
    },
    Geral: {
      toString: () => 'App.Geral',
      TrocarUnidade: 'App.Geral.TrocarUnidade',
    },
    MeioPagamento: {
      toString: () => 'App.MeioPagamento',
      Visualizar: 'App.MeioPagamento.Visualizar',
      Criar: 'App.MeioPagamento.Criar',
      Excluir: 'App.MeioPagamento.Excluir',
    },
    Mural: {
      toString: () => 'App.Mural.Visualizar',
      Visualizar: 'App.Mural.Visualizar',
    },
    Pessoas: {
      toString: () => 'App.Pessoas',
      Visualizar: 'App.Pessoas.Visualizar',
      Criar: 'App.Pessoas.Criar',
      Editar: 'App.Pessoas.Editar',
      Excluir: 'App.Pessoas.Excluir',
      VincularPermissao: 'App.Pessoas.VincularPermissao',
    },
    Portaria: {
      toString: () => 'App.Portaria',
      Visualizar: 'App.Portaria.Visualizar',
      Cancelar: 'App.Portaria.Cancelar',
      Autorizar: {
        toString: () => 'App.Portaria.Autorizar',
        Delivery: 'App.Portaria.Autorizar.Delivery',
        Entrega: 'App.Portaria.Autorizar.Entrega',
        FuncionarioDomestico: 'App.Portaria.Autorizar.FuncionarioDomestico',
        PrestadorServico: 'App.Portaria.Autorizar.PrestadorServico',
        TransportePassageiro: 'App.Portaria.Autorizar.TransportePassageiro',
        Visitante: 'App.Portaria.Autorizar.Visitante',
      },
    },
    Reserva: {
      toString: () => 'App.Reserva',
      Visualizar: 'App.Reserva.Visualizar',
      Criar: 'App.Reserva.Criar',
      Cancelar: 'App.Reserva.Cancelar',
    },
    Votacao: {
      toString: () => 'App.Votacao',
      CancelarProcuracao: 'App.Votacao.CancelarProcuracao',
      CriarProcuracao: 'App.Votacao.CriarProcuracao',
      Visualizar: 'App.Votacao.Visualizar',
    },
    Encomenda: {
      toString: () => 'App.Encomenda',
      Criar: 'App.Encomenda.Criar',
      Visualizar: 'App.Encomenda.Visualizar',
      Atualizar: 'App.Encomenda.Atualizar',
      Excluir: 'App.Encomenda.Excluir',
    }
  },
};
