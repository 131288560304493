import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { StepsStyleConfig as Steps } from "chakra-ui-steps";
import colors from "core/resources/theme/colors";

const theme = extendTheme({
  components: {
    Steps,
    Text: {
      baseStyle: {
        fontFamily: "Poppins",
        fontSize: [12, 12, 14, 14, 14],
      },
    },
    Button: {
      baseStyle: {
        fontWeight: "normal",
        fontFamily: "Poppins",
        fontSize: [12, 12, 14, 14, 14],
      },
    },
    Box: {
      baseStyle: {
        fontFamily: "Poppins",
        fontSize: [12, 12, 14, 14, 14],
      },
    },
  },
  breakpoints: {
    sm: "320px",
    md: "768px",
    lg: "900px",
    xl: "1200px",
    "2xl": "1536px",
  },
  colors: {
    blue: {
      100: colors.blue100,
      200: colors.blue200,
      300: colors.blue300,
      400: colors.blue400,
      500: colors.blue500,
    },
    green: {
      100: colors.green100,
      500: colors.green500,
      900: colors.green,
    },
    yellow: {
      500: colors.yellow,
    },
    red: {
      500: colors.red,
    },
  },
});

function ChakraUiProvider({ children }: any) {
  return <ChakraProvider theme={theme}>{children}</ChakraProvider>;
}

export default ChakraUiProvider;
