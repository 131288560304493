import { Container, Flex } from "@chakra-ui/layout";
import colors from "core/resources/theme/colors";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";

import { FaAngleRight } from "react-icons/fa";
import { useHistory } from "react-router";
import { useBreadcrumbStore } from "core/features/breadcrumbs";

function SubHeader() {
  const history = useHistory();

  const { items } = useBreadcrumbStore();

  if (!items.length) {
    return null;
  }

  return (
    <Flex
      bg={`linear-gradient(to right, ${colors.primaryDark}, ${colors.primary}, ${colors.blue500})`}
      py="16px"
    >
      <Container maxW="100%">
        <Breadcrumb spacing="8px" separator={<FaAngleRight color="white" />}>
          {items.map((item, index) => (
            <BreadcrumbItem
              key={index}
              isCurrentPage={items.length - 1 === index}
            >
              <BreadcrumbLink
                _hover={{ color: "white" }}
                fontSize="13px"
                cursor={items.length - 1 === index ? "text" : "pointer"}
                color={items.length - 1 === index ? "white" : "whiteAlpha.700"}
                onClick={() => {
                  if (item.url) {
                    history.push(item.url);
                  }
                }}
              >
                {item.name}
              </BreadcrumbLink>
            </BreadcrumbItem>
          ))}
        </Breadcrumb>
      </Container>
    </Flex>
  );
}

export default SubHeader;
