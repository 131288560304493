import { Switch, Route } from "react-router-dom";
import AuthRoute from "components/AuthRoute";
import ErrorsPages from "./Errors";
import AuthPages from "./Auth";
import AppPages from "./App";
import ZoomPage from "./Zoom";
import LoginToken from "./Auth/LoginToken";
import PdfPages from "ui/pages/Pdf";

function Pages() {
  return (
    <Switch>
      <Route exact path="/auth/token" component={LoginToken} />
      <Route exact path="/zoom/:votacaoId" component={ZoomPage} />
      <Route path="/pdf" component={PdfPages} />
      <Route path="/auth" component={AuthPages} />
      <Route path="/errors" component={ErrorsPages} />
      <AuthRoute path="/" component={AppPages} />
    </Switch>
  );
}

export default Pages;
