import { useQuery } from "react-query";
import http from "infra/http";
import { BoletoDetalheDto } from "../typings";

function useBoleto(id: string) {
  return useQuery(
    [`mobile/v1/boletos/${id}`],
    async () => {
      const res = await http.get<BoletoDetalheDto>(
        `mobile/v1/boletos/${id}`
      );

      return res.data;      
    },
    {
      enabled: !!id,
    }
  );
}

export default useBoleto;
