import {
  Flex,
  Box,
  Text,
  Stack,
  Radio,
  RadioGroup,
  CheckboxGroup,
  Skeleton,
  Checkbox,
} from "@chakra-ui/react";
import { useState, useCallback } from "react";
import PrimaryButton from "ui/components/Button/PrimaryButton";
import usePergunta from "core/features/assembleia/hooks/usePergunta";
import { useRouteMatch } from "react-router-dom";
import Toolbar from "ui/components/Toolbar";
import SidebarContainer from "../SitebarContainer";
import { showError } from "infra/helpers/alerta";
import RefetchButton from "ui/pages/App/Assembleia/Detalhes/components/RefetchButton";

interface VotacaoBoxProps {
  perguntaId?: string;
  onReturn: () => any;
  onConfirm: (selected: string | string[]) => any;
}

function VotacaoBox({ perguntaId, onReturn, onConfirm }: VotacaoBoxProps) {
  const route = useRouteMatch<any>();
  const [radio, setRadio] = useState<string | string[]>();
  const {
    data: pergunta,
    isFetching,
    refetch: refetchPergunta,
  } = usePergunta(route.params.id, perguntaId);

  useCallback(() => {
    if (!isFetching && pergunta!.respondida) {
      setRadio(pergunta!.respostas);
    }
  }, [pergunta, isFetching]);

  return (
    <Box>
      <Toolbar
        title="Votação"
        onBack={onReturn}
        rightElement={
          <Box ml="auto">
            <RefetchButton refetch={refetchPergunta} />
          </Box>
        }
      />

      <SidebarContainer>
        <Flex flexDir="column">
          <Text fontWeight="bold" fontSize="15px">
            {pergunta?.titulo ?? "--"}
          </Text>
          <Text fontSize="12px" color="gray.600">
            {pergunta?.descricao ?? null}
          </Text>
          <Text fontSize="10px" color="grey">
            Selecione até {pergunta?.numeroMaximoResposta || "*"} resposta
            {pergunta?.numeroMaximoResposta !== 1 ? "s" : null}
          </Text>
        </Flex>
        <Flex flex={1} mt="15px">
          {!isFetching ? (
            <VotacaoWrapper
              multiplaResposta={!!pergunta?.multiplaResposta}
              onChange={(ev: any) => {
                if (
                  pergunta?.multiplaResposta &&
                  (pergunta?.numeroMaximoResposta !== undefined ||
                    pergunta?.numeroMaximoResposta !== null) &&
                  ev?.length > pergunta?.numeroMaximoResposta!
                ) {
                  return showError(
                    `É permitido selecionar até ${pergunta.numeroMaximoResposta} opcões`
                  );
                }
                setRadio(ev);
              }}
              value={radio}
            >
              <Stack>
                {pergunta?.opcoes.map((val) => (
                  <VotacaoOpcao
                    id={val.id}
                    multiplaResposta={!!pergunta.multiplaResposta}
                    titulo={val.titulo}
                    key={val.id}
                  />
                ))}
              </Stack>
            </VotacaoWrapper>
          ) : (
            <Box w="100%">
              <Skeleton w="25%" mb="8px" h="20px" />
              <Skeleton w="40%" mb="8px" h="20px" />
              <Skeleton w="35%" h="20px" />
            </Box>
          )}
        </Flex>

        <Flex>
          {(pergunta?.multiplaResposta && radio?.length) ||
          (!pergunta?.multiplaResposta && radio) ? (
            <PrimaryButton
              w="100%"
              backgroundColor="green"
              onClick={() => onConfirm(radio)}
            >
              Confirmar resposta
            </PrimaryButton>
          ) : (
            <PrimaryButton w="100%" backgroundColor="grey">
              Escolha uma resposta
            </PrimaryButton>
          )}
        </Flex>
      </SidebarContainer>
    </Box>
  );
}

function VotacaoWrapper({
  multiplaResposta,
  onChange,
  value,
  children,
}: {
  children: any;
  value: any;
  onChange: any;
  multiplaResposta: boolean;
}) {
  if (multiplaResposta) {
    return (
      <CheckboxGroup
        onChange={(ev) => {
          onChange(ev);
        }}
        value={value}
      >
        {children}
      </CheckboxGroup>
    );
  }

  return (
    <RadioGroup
      onChange={(ev) => {
        onChange(ev);
      }}
      value={value}
    >
      {children}
    </RadioGroup>
  );
}

function VotacaoOpcao({
  titulo,
  id,
  multiplaResposta,
}: {
  titulo: string;
  id: string;
  multiplaResposta: boolean;
}) {
  if (multiplaResposta) {
    return (
      <Checkbox value={id} name={titulo}>
        {titulo}
      </Checkbox>
    );
  }
  return (
    <Radio value={id} name={titulo}>
      {titulo}
    </Radio>
  );
}

export default VotacaoBox;
