import { Tag } from "@chakra-ui/react";
import moment from "moment";
import { useState, useEffect } from "react";

interface TagPagProps {
  vencimento: string;
  pagamento: string | null;
}

function TagPago({ vencimento, pagamento }: TagPagProps) {
  // 0 - Pendente, 1 - Pago, 2 - Atrasado
  const [isStatusPagamento, setIsStatusPagamento] = useState(0);
  const [diasAtrasados, setDiasAtrasados] = useState(0);

  useEffect(() => {
    if (pagamento) {
      setIsStatusPagamento(1);
    } else {
      if (moment().isAfter(moment(vencimento))) {
        setIsStatusPagamento(2);
        setDiasAtrasados(-moment(vencimento).diff(moment(), "days"));
      }
    }
  }, [pagamento, vencimento]);

  function tagSwitch(statusParam: any) {
    switch (statusParam) {
      case 0:
        return ["Pendente", "orange"];
      case 1:
        return ["Pago " + moment(pagamento).format("DD/MM/YYYY"), "green"];
      case 2:
        return [
          "Atrasado há " +
            diasAtrasados +
            (diasAtrasados === 1 ? " dia" : " dias"),
          "red",
        ];
      default:
        return ["Pendente", "orange"];
    }
  }

  return (
    <Tag
      size="md"
      colorScheme={tagSwitch(isStatusPagamento)[1]}
      borderRadius="full"
    >
      {tagSwitch(isStatusPagamento)[0]}
    </Tag>
  );
}

export default TagPago;
