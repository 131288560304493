import { Container, Flex, Box } from "@chakra-ui/react";
import HeaderMenu from "./HeaderMenu";
import MenuUser from "./MenuUser";
import { StyledHeader } from "./styles";
import logo from "assets/images/svgs/logo.svg";
import { Image } from "@chakra-ui/react";
import { useCurrentAuth } from "core/features/auth/auth.store";
import { useCurrentTenant } from "core/features/auth/tenant.store";
import { Menu } from "core/features/menu/typings/menu";
import { useMenu } from "core/features/menu";
import { useCallback, useMemo } from "react";
import { useWindowSize } from "infra/helpers/window";
import ResponsiveMenu from "./ResponsiveMenu";

function Header() {
  const { menu } = useMenu();

  const { auth } = useCurrentAuth();
  const { tenant } = useCurrentTenant();

  const checkVisible = useCallback(
    (menus: Menu[]) => {
      return menus.filter((item) => {
        const possuiAcesso = item.visible(tenant, auth);

        return possuiAcesso;
      });
    },
    [auth, tenant]
  );

  const menus = useMemo(() => {
    const result: Menu[] = [];
    for (const item of checkVisible([...menu()])) {
      for (const submenu of item.children) {
        submenu.children = checkVisible(submenu.children);
      }

      item.children = checkVisible(item.children);

      result.push(item);
    }

    return result;
  }, [menu, checkVisible]);

  const windowSize = useWindowSize();

  const isDesktop = windowSize.width > 992;
  const isMobile = windowSize.width <= 992;

  return (
    <StyledHeader width={windowSize.width}>
      <Container maxW="100%">
        <Flex flex="1">
          <Flex flex={isMobile ? 1 : undefined} alignItems="center">
            <Image className="logo" w={"150px"} src={logo} />
          </Flex>

          {isDesktop ? (
            <Flex flex="1" justifyContent="center">
              <Box>
                <HeaderMenu menus={menus} />
              </Box>
            </Flex>
          ) : null}
          {isDesktop ? (
            <Flex>
              <MenuUser />
            </Flex>
          ) : null}

          {isMobile ? <ResponsiveMenu items={menus} /> : null}
        </Flex>
      </Container>
    </StyledHeader>
  );
}

export default Header;
