import styled from "styled-components";

export const MettingContainer = styled.div`
  .MuiPaper-rounded {
    border-radius: 0 !important;
  }
  

  canvas {
    width: 100% !important;
  }

  
  .react-draggable {
    width: 100%;
    transform: none !important;

    #suspension-view-tabpanel-speaker {
      > .MuiBox-root {
        height: calc(100vh - 80px) !important;
      }

      .MuiBox-root div + div {
        width: 100% !important;
      }

      #zoom-sdk-video-canvas {
        height: calc(100vh - 80px) !important;
      }
    }
  }
`;
