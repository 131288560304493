export enum TenantStatusEnum {
  Ativo = "E2D477BD-0E21-4D0E-8AF4-092EC3CB9360",
  Inativo = "E2D477BD-0E21-4D0E-8AF4-092EC3CB9361",
  Bloqueado = "E2D477BD-0E21-4D0E-8AF4-092EC3CB9362",
}

export enum TipoEstruturaMoradiaEnum {
  Bloco = "bloco",
  Quadra = "quadra",
}

export enum TenantTipoEstrutura {
  Vertical = "vertical",
  Horizontal = "horizontal",
}

export enum TipoTenantEnum {
  Admin = 'E2D477BD-0E21-4D0E-8AF4-092EC3CB9361',
  Condominio = 'BE2E148C-B230-441F-A591-3F3CC6F63870',
  Imobiliaria = 'D66FB0CF-727B-4E20-8B1E-035595719DEF',
  Construtora = '2EDA8DC7-8BED-4337-A079-7294C0F760D6',
  Incorporadora = 'D23D6798-7A45-43C5-9141-15F465BA58A2',
  Administradora = '5B04913A-6C46-4EBE-BFB3-F311EE9E0927',
}

export enum TenantTipoHabitacao {
  Residencial = "residencial",
  Comercial = "comercial",
  Misto = "misto",
}

export enum FormaPagamento {
  BoletoBancario = "E2D477BD-0E21-4D0E-8AF4-092EC3CB9361",
  CartaoCredito = "E2D477BD-0E21-4D0E-8AF4-092EC3CB9362",
  CartaoDebito = "E2D477BD-0E21-4D0E-8AF4-092EC3CB9363",
  Pix = "E2D477BD-0E21-4D0E-8AF4-092EC3CB9364",
  Transferencia = "E2D477BD-0E21-4D0E-8AF4-092EC3CB9365",
  Cheque = "E2D477BD-0E21-4D0E-8AF4-092EC3CB9366",
}

export enum InadiplenciaTipo {
  ProblemasFinanceiros = "problemas financeiros",
  NaoRecebeuCobranca = "nao recebeu a cobranca",
  NaoConcordaComCobranca = "nao concorda com a cobranca",
  Outros = "outros",
}
