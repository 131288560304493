import { Portal } from "@chakra-ui/react";
import { Button } from "@chakra-ui/button";
import { Box, Flex } from "@chakra-ui/layout";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/menu";
import { Table, TableProps } from "antd";
import { ColumnType } from "antd/lib/table";
import { useEffect, useState, useMemo } from "react";
import { GoKebabVertical } from "react-icons/go";
import Pagination from "../Pagination";
import { TableContainer } from "./styles";
import { checkPermission } from "infra/helpers/permission";
import styled from "styled-components";
import BottomTableFixed, {
  ActionBottomType,
} from "ui/components/BottomTableFixed";

const Scroll = styled.div`
  .ant-table td {
    width: fit-content;
    overflow: hidden;
  }
`;
export interface NewTableProps<T> {
  hideShadow?: boolean;
  CustomFooter?: React.ReactNode;
  actions?: {
    invisible?: boolean;
    title: string | ((_v: T) => any);
    permission?: string;
    icon?: (_v: T) => any;
    onClick: (_v: T, index?: number) => void;
    shouldRender?: (original: T) => boolean;
  }[];
  customPagination?: {
    onChangePage: (p: number, perPage: number) => void;
    currentPage?: number;
    itemsPerPage?: number;
    totalItems?: number;
  };
  customSelectedRows?: {
    handler: (v: T[]) => void;
    selecteds: T[];
    actions: ActionBottomType[];
  };
  showCustomSelectionActions?: boolean;
  maxSelectedRows?: number;
  actionsContainerRef?: React.RefObject<HTMLElement | null> | undefined;
}

function NewTable<T = any>({
  customPagination,
  actions,
  hideShadow,
  CustomFooter,
  customSelectedRows,
  showCustomSelectionActions = true,
  maxSelectedRows,
  actionsContainerRef,
  ...props
}: NewTableProps<T> & Partial<TableProps<any>>) {
  const [selectedRows, setSelectedRows] = useState<T[]>([]);

  useEffect(() => {
    if (
      props.dataSource?.length === 0 &&
      customPagination?.currentPage &&
      customPagination.currentPage > 1
    ) {
      customPagination?.onChangePage(
        customPagination.currentPage - 1,
        customPagination.itemsPerPage ?? 10
      );
    }
  }, [props.dataSource, customPagination]);
  let _columns: ColumnType<T>[] = props.columns ?? [];

  actions =
    actions?.filter((item) => {
      if (item.permission) {
        return checkPermission(item.permission);
      }

      if (item.invisible) {
        return false;
      }

      return true;
    }) ?? [];

  if (actions?.length) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _columns = [
      ..._columns,
      {
        title: "",
        width: "70px",
        align: "right",
        render: (original: T, record?: T, indexOriginal?: number) => {
          return (
            <Flex justifyContent="flex-end" overflow="visible">
              <Menu>
                <MenuButton
                  onClick={(ev) => {
                    ev.stopPropagation();
                  }}
                  type="button"
                >
                  <Button
                    variant="outline"
                    rounded="full"
                    w="40px"
                    h="40px"
                    type="button"
                  >
                    <GoKebabVertical />
                  </Button>
                </MenuButton>
                <Portal containerRef={actionsContainerRef}>
                  <MenuList>
                    {actions?.map((action, index) =>
                      (
                        action.shouldRender
                          ? action.shouldRender?.(original)
                          : true
                      ) ? (
                        <MenuItem
                          key={index}
                          fontSize="14px"
                          onClick={(ev) => {
                            ev.preventDefault();
                            ev.stopPropagation();
                            action.onClick(original, indexOriginal);
                          }}
                          type="button"
                        >
                          <Flex alignItems="center" gridGap="8px" flex={1}>
                            {typeof action.icon === "function"
                              ? action.icon(original)
                              : null}
                            {typeof action.title === "function"
                              ? action.title(original)
                              : action.title}
                          </Flex>
                        </MenuItem>
                      ) : null
                    )}
                  </MenuList>
                </Portal>
              </Menu>
            </Flex>
          );
        },
      } as any,
    ].filter((item: any) => item);
  }

  const customProps: any = useMemo(() => {
    let result: any = {};

    if (
      customSelectedRows &&
      typeof customSelectedRows.handler === "function"
    ) {
      result.rowSelection = {
        type: "checkbox",
        preserveSelectedRowKeys: true,
        onChange: (_event: any, v: T[]) => {
          let rows = v;

          if (maxSelectedRows) {
            rows =
              v.length > 2 ? [] : rows.slice(rows.length - maxSelectedRows);
          }

          customSelectedRows.handler(rows);
          setSelectedRows(rows);
        },
        selectedRowKeys: selectedRows.map((item: any) => item.id),
        hideSelectAll: maxSelectedRows
          ? maxSelectedRows < (customPagination?.itemsPerPage || 10)
          : false,
      };
    }

    return result;
  }, [
    customPagination?.itemsPerPage,
    customSelectedRows,
    maxSelectedRows,
    selectedRows,
  ]);

  return (
    <>
      <TableContainer hideShadow={hideShadow} flatBottom={!!CustomFooter}>
        <Box
          boxShadow={
            hideShadow ? undefined : "0 0 16px 3px rgba(0, 0, 0, 0.05)"
          }
          rounded="16px"
          mb="32px"
        >
          <Scroll>
            <Table
              style={{
                borderRadius: "16px",
                borderBottomLeftRadius: CustomFooter ? 0 : "16px",
                borderBottomRightRadius: CustomFooter ? 0 : "16px",
              }}
              pagination={false}
              rowKey="id"
              scroll={{ x: true }}
              {...customProps}
              {...props}
              columns={_columns}
            />
            {CustomFooter}
          </Scroll>

          {typeof customPagination?.onChangePage === "function" ? (
            <Pagination
              style={{ margin: 16 }}
              current={customPagination?.currentPage ?? 1}
              total={customPagination?.totalItems ?? 0}
              pageSize={customPagination?.itemsPerPage ?? 0}
              onChange={(page, pageSize) => {
                customPagination.onChangePage(page, pageSize);
              }}
            />
          ) : null}
        </Box>
      </TableContainer>

      {selectedRows?.length &&
      customSelectedRows &&
      showCustomSelectionActions ? (
        <BottomTableFixed
          selectedRows={selectedRows}
          onClose={() => {
            setSelectedRows([]);
            customSelectedRows.handler([]);
          }}
          actions={customSelectedRows?.actions!}
        />
      ) : null}
    </>
  );
}

export default NewTable;
