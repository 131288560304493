import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import { Box, Text } from "@chakra-ui/react";
import {
  Control,
  useController,
  RegisterOptions,
  ControllerRenderProps,
  ControllerFieldState,
  UseFormStateReturn,
} from "react-hook-form";
import { FieldContainer } from "./styles";
import moment from "moment";
import { useMemo } from "react";
import { formatMoney } from "infra/helpers/number";
import InputMoney from "../InputMoney";

function Field({
  control,
  label,
  shouldUnregister = false,
  name,
  rules,
  render,
  inputProps,
  errorMessage,
  readonly,
}: {
  control: Control<any, object>;
  rules?: Omit<
    RegisterOptions<any, any>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
  label?: string;
  shouldUnregister?: boolean;
  name: string;
  render?: ({
    field,
    fieldState,
    formState,
    isInvalid,
  }: {
    field: ControllerRenderProps<any, any>;
    fieldState: ControllerFieldState;
    formState: UseFormStateReturn<any>;
    isInvalid?: boolean;
  }) => React.ReactElement;
  inputProps?: any;
  errorMessage?: string;
  readonly?: boolean;
}) {
  const { field, fieldState, formState } = useController({
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue: "",
  });

  let { invalid, error } = fieldState;

  invalid = invalid || !!errorMessage;

  const isRequired = useMemo(() => {
    if (typeof rules?.required === "object") {
      return rules.required.value;
    }

    if (rules?.required) {
      return true;
    }

    return false;
  }, [rules]);

  return (
    <FieldContainer isInvalid={invalid} fontSize={inputProps?.fontSize || 13}>
      <FormControl isInvalid={invalid}>
        <Box>
          {label ? (
            <FormLabel
              mb="0"
              color="gray.600"
              fontWeight="400"
              fontSize="13px"
              noOfLines={1}
            >
              {label}
              {isRequired ? "*" : null}
            </FormLabel>
          ) : null}

          {!readonly && typeof render !== "function" ? (
            <RenderInput
              field={field}
              inputProps={inputProps}
              isInvalid={invalid}
            />
          ) : null}

          {!readonly && typeof render === "function"
            ? render({ field, fieldState, formState, isInvalid: invalid })
            : null}

          {readonly ? (
            <RenderReadonly field={field} inputProps={inputProps} />
          ) : null}

          {invalid ? (
            <FormErrorMessage mt="2px">
              {error?.message || errorMessage || "Campo inválido"}
            </FormErrorMessage>
          ) : null}
        </Box>
      </FormControl>
    </FieldContainer>
  );
}

function RenderInput({
  field,
  inputProps,
  isInvalid,
}: {
  field: ControllerRenderProps;
  inputProps?: any;
  isInvalid?: boolean;
}) {
  if (inputProps?.type === "money") {
    return <InputMoney {...field} {...inputProps} isInvalid={isInvalid} />;
  }

  return <Input {...field} {...inputProps} isInvalid={isInvalid} />;
}

function RenderReadonly({
  field,
  inputProps,
}: {
  field: ControllerRenderProps;
  inputProps: any;
}) {
  if (inputProps?.type === "date") {
    return <Text>{moment(field.value).format("DD/MM/YYYY")}</Text>;
  }

  if (inputProps?.type === "money") {
    return <Text>{formatMoney(field.value ?? 0)}</Text>;
  }

  return <Text>{field.value}</Text>;
}

export default Field;
