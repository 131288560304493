import { Box } from "@chakra-ui/layout";
import Header from "./Header";
import SubHeader from "./SubHeader";
import { useWindowSize } from "infra/helpers/window";

function Layout({ children }: any) {
  const windowSize = useWindowSize();

  const isMobile = windowSize.width <= 992;

  return (
    <Box minH="100vh" overflowX={"hidden"} bg={"#f2f2f2"} maxW="100vw">
      <Box>
        <Header />

        <Box mt={isMobile ? 0 : "69px"}>
          <SubHeader />
          <Box pt="32px">{children}</Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Layout;
