import { useQuery } from "react-query";
import axios from "infra/http";
import { VotacaoPerguntaEstatisticaDetalhesDto } from "../typings";

function useVotacaoStats(id: string, perguntaId: string) {
  return useQuery(
    [`/mobile/v1/votacao/${id}/pergunta/${perguntaId}/estatistica`],
    async () => {
      const res = await axios.get<VotacaoPerguntaEstatisticaDetalhesDto>(
        `/mobile/v1/votacao/${id}/pergunta/${perguntaId}/estatistica`
      );

      return res.data;
    },
    {
      enabled: !!id,
    }
  );
}

export default useVotacaoStats;
