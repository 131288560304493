import useHasPermission from "core/features/permissions/hooks/useHasPermission";
export interface CanProps {
  permission: string | string[];
  children: any;
}

function Can({ permission, children }: CanProps) {
  const hasPermission = useHasPermission(permission);

  if (!hasPermission) {
    return null;
  }

  return children;
}

export default Can;
