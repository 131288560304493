import styled from "styled-components";

export const MeetingContainer = styled.div`
  /* .MuiPaper-rounded {
    border-radius: 16px !important;
  }

  canvas,
  #ZOOM_WEB_SDK_SELF_VIDEO  {
    width: 100% !important;
  }

  .react-draggable {
    width: 100%;
    transform: none !important;

    #suspension-view-tabpanel-speaker {
      > .MuiBox-root {
        height: 450px !important;
      }

      .MuiBox-root div + div {
        width: 100% !important;
      }

      #zoom-sdk-video-canvas {
        height: 450px !important;
      }
    }
  } */
`;
