const error: any = {
  "auth/user-not-found": "Usuário não encontrado",
  "auth/email-already-in-use": "Email já esta em uso no sistema",
  "auth/account-exists-with-different-credential":
    "Essa conta já existe no sistema",
  "auth/invalid-email": "Usuário inválido",
  "auth/invalid-password": "Senha inválida",
  "auth/wrong-password": "Senha incorreta",
  "auth/requires-recent-login":
    "Antes de continuar, será necessário fazer login novamente!",
  "auth/weak-password": "A senha está muito fraca",
};

export default error;
