import { Box, Center, Flex, Text } from "@chakra-ui/react";
import colors from "core/resources/theme/colors";
import { AiOutlineArrowLeft, AiOutlineCloseCircle } from "react-icons/ai";

function Toolbar({
  title,
  onBack,
  rightElement,
  onClose,
}: {
  title: string;
  onBack?: () => void;
  rightElement?: React.ReactNode;
  onClose?: () => any;
}) {
  return (
    <Box>
      <Box
        borderTopRadius="15px"
        backgroundColor={colors.primary}
        padding="14px"
        borderBottom="2px"
        borderBottomColor={colors.secondary}
        marginBottom="0px"
      >
        <Flex flexDir="row" gap="10px">
          {typeof onBack === "function" ? (
            <Center>
              <AiOutlineArrowLeft
                color="white"
                fontSize="20px"
                onClick={onBack}
                cursor="pointer"
              />
            </Center>
          ) : null}

          <Text fontSize="18px" color="white">
            {title}
          </Text>

          {rightElement}

          {typeof onClose === "function" ? (
            <Center ml="auto">
              <AiOutlineCloseCircle
                color="white"
                fontSize="28px"
                onClick={onClose}
                cursor="pointer"
              />
            </Center>
          ) : null}
        </Flex>
      </Box>
    </Box>
  );
}

export default Toolbar;
