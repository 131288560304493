import { Box, Text } from "@chakra-ui/layout";
import { useCurrentAuth } from "core/features/auth/auth.store";
import { UserUnidade } from "core/features/auth/typings/user";
import { useCurrentUnidade } from "core/features/auth/unidade.store";
import colors from "core/resources/theme/colors";
import { useMemo } from "react";

function UnidadeList({ search }: { search: string }) {
  const { auth } = useCurrentAuth();

  const items: UserUnidade[] = useMemo((): UserUnidade[] => {
    if (!auth || !auth.unidades?.length) {
      return [];
    }

    let unidades = auth.unidades ?? [];

    if (search) {
      unidades = unidades.filter((u) =>
        u.numero.toLowerCase().includes(search.toLowerCase())
      );
    }

    return unidades;
  }, [auth, search]);

  if (!auth || !auth.unidades?.length) {
    return null;
  }

  return (
    <>
      {items.map((item, key) => (
        <UnidadeListItem key={key} item={item} />
      ))}
    </>
  );
}

function UnidadeListItem({ item }: { item: UserUnidade }) {
  const { unidade, selecionar: selecionarTenant } = useCurrentUnidade();

  return (
    <>
      <Box
        onClick={() => {
          selecionarTenant(item);

          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        }}
        color={colors.primary}
        fontWeight={"normal"}
        cursor="pointer"
        bg={unidade?.id === item.id ? colors.blue100 : "transparent"}
        _hover={{ bg: "#f7f7f7" }}
        py="8px"
        pl={"8px"}
        d="flex"
        gap="6px"
      >
        <Text color={colors.primary} fontWeight={"bold"}>
          {item.numero} {item.blocoQuadra ? ` / ${item.blocoQuadra}` : ""}
        </Text>
        -{"  "}
        {item.tenantNome}
      </Box>
    </>
  );
}

export default UnidadeList;
