import { Box, BoxProps, Container, Flex, Text } from "@chakra-ui/layout";
import { Menu } from "core/features/menu/typings/menu";
import colors from "core/resources/theme/colors";
import Fade from "react-fade-in";
import { trackEvent } from "infra/helpers/event";

interface HeaderMenuItemProps extends BoxProps {
  item: Menu;
  submenuOpen: boolean;
  onCloseSubmenu: any;
  onOpenSubmenu: any;
  color?: string;
}

function HeaderMenuItem({
  item,
  submenuOpen,
  onCloseSubmenu,
  onOpenSubmenu,
  color,
  ...props
}: HeaderMenuItemProps) {
  return (
    <>
      <Flex
        _hover={{
          color: color || colors.primary,
          boxShadow: color ? "none" : `inset 0px -5px 0px ${colors.primary}`,
        }}
        boxShadow={
          submenuOpen ? `inset 0px -5px 0px ${colors.primary}` : undefined
        }
        onClick={item.action}
        transition="linear all .1s"
        cursor="pointer"
        py="24px"
        px="8px"
        fontSize="14px"
        gridGap="8px"
        color={color || (submenuOpen ? colors.primary : colors.darkBlueGray)}
        onMouseOver={() => {
          if (item.children.length) {
            onOpenSubmenu();
          } else {
            onCloseSubmenu();
          }
        }}
        {...props}
      >
        {item.icon}
        {item.title}
      </Flex>

      {submenuOpen ? (
        <Flex
          onMouseLeave={() => onCloseSubmenu()}
          bg={colors.blue200}
          h="400px"
          pos="absolute"
          top={69}
          left={0}
          right={0}
          w="100%"
        >
          <Container maxW="container.xl">
            <Fade visible={submenuOpen}>
              <Flex gridGap="32px" flexWrap="wrap">
                {item.children.map((submenu, index) => (
                  <Flex key={index} flexDir="column">
                    <Box pt="16px" mb="16px" h="35px">
                      <Text fontSize="16px">{submenu.title}</Text>
                    </Box>

                    <Flex gridGap="16px">
                      {submenu.children.map((child, index2) => (
                        <Flex
                          w={"110px"}
                          h={"110px"}
                          cursor="pointer"
                          rounded="16px"
                          shadow="xs"
                          key={index2}
                          bg="white"
                          p="8px"
                          flexDir="column"
                          onClick={() => {
                            if (typeof child.action === "function") {
                              trackEvent(`ClickMenu-${child.title}`);
                              child.action();
                            }

                            onCloseSubmenu();
                          }}
                        >
                          <Flex
                            flex="1"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Flex
                              w="40px"
                              h="40px"
                              rounded="20px"
                              bg={child.color}
                              justifyContent="center"
                              alignItems="center"
                              color={"white"}
                            >
                              {child.icon}
                            </Flex>
                          </Flex>
                          <Flex
                            flex="1"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Text textAlign="center" fontSize="12px">
                              {child.title}
                            </Text>
                          </Flex>
                        </Flex>
                      ))}
                    </Flex>
                  </Flex>
                ))}
              </Flex>
            </Fade>
          </Container>
        </Flex>
      ) : null}
    </>
  );
}

export default HeaderMenuItem;
