import { Flex } from "@chakra-ui/react";
import useBoletos from "core/features/boleto/hooks/useBoletos";
import useFiltro from "infra/helpers/useFiltro";
import { useCallback } from "react";
import NewTable from "ui/components/NewTable";
import useTableBoletoColumns from "ui/pages/App/Boletos/components/TableBoletos/hooks/useTableBoletoColumns";

function TableBoletos({ clickPix }: { clickPix: (id: string) => any }) {
  const { filtro, onChangeFiltro } = useFiltro();

  const { data, isLoading } = useBoletos(filtro);

  const boletoClickHandler = useCallback((referencia: string) => {
    window.open(referencia, "_blank");
  }, []);

  const columns = useTableBoletoColumns({
    boletoClickHandler,
    pixClickHandler: clickPix,
  });

  return (
    <>
      <Flex flexDir="column">
        <NewTable
          columns={columns}
          dataSource={data?.items ?? []}
          loading={isLoading}
          customPagination={{
            currentPage: data?.meta.currentPage,
            itemsPerPage: data?.meta.itemsPerPage,
            totalItems: data?.meta.totalItems,
            onChangePage: (page) => onChangeFiltro("page", page),
          }}
        />
      </Flex>
    </>
  );
}

export default TableBoletos;
