import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import useHasPermission from "core/features/permissions/hooks/useHasPermission";
import { useCurrentAuth } from "core/features/auth/auth.store";

export interface AuthRouteProps extends RouteProps {
  permissions?: string[];
}

const AuthRoute: React.FC<AuthRouteProps> = ({ permissions, ...props }) => {
  const { isLogged } = useCurrentAuth();
  const hasPermission = useHasPermission(permissions ?? []);

  if (!isLogged) {
    return <Redirect to="/auth/login" />;
  }

  if (permissions !== undefined && !hasPermission) {
    return <Redirect to="/errors/sem-permissao" />;
  }

  return <Route {...props} />;
};

export default AuthRoute;
