import { useCallback, useState } from "react";
import {
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  FacebookAuthProvider,
  GoogleAuthProvider,
  signInWithCustomToken,
} from "firebase/auth";

import { showError } from "infra/helpers/alerta";
import error from "core/resources/firebase/error";
import { useCurrentAuth } from "../auth.store";
import { registerMixpanel } from "infra/packages/mixpanel";
import { trackEvent } from "infra/helpers/event";

const auth = getAuth();

export default function useLogin() {
  const [isLoading, setIsLoading] = useState(false);
  const { updateIsLogged } = useCurrentAuth();

  const handleLogin = useCallback(
    async (provider) => {
      try {
        setIsLoading(true);

        const { user } = await signInWithPopup(auth, provider);

        updateIsLogged();

        registerMixpanel({
          id: user.uid,
          nome: user.displayName!,
          email: user.email!,
        });

        trackEvent("login.social.sucesso", {
          id: user.uid,
          email: user.email,
          provider: user.providerData[0].providerId,
        });
      } catch (err) {
        trackEvent("login.social.erro", {
          provider: provider,
          error: error[(err as any).code] ?? error.message ?? null,
        });

        showError(error[(err as any).code]);
        setIsLoading(false);
      }
    },
    [updateIsLogged]
  );

  const handleLoginGoogle = useCallback(async () => {
    return handleLogin(new GoogleAuthProvider());
  }, [handleLogin]);

  const handleLoginFacebook = useCallback(async () => {
    return handleLogin(new FacebookAuthProvider());
  }, [handleLogin]);

  const handleLoginEmail = useCallback(
    async ({ username, password }) => {
      try {
        setIsLoading(true);

        const { user } = await signInWithEmailAndPassword(
          auth,
          username,
          password
        );

        registerMixpanel({
          id: user.uid,
          nome: user.displayName!,
          email: user.email!,
        });

        trackEvent("login.email.sucesso", {
          id: user.uid,
          email: user.email,
          provider: "email",
        });

        updateIsLogged();
      } catch (err) {
        trackEvent("login.email.erro", {
          email: username,
          error: error[(err as any).code] ?? error.message ?? null,
        });
        showError(error[(err as any).code] ?? "Não foi possível entrar");
        setIsLoading(false);
      }
    },
    [updateIsLogged]
  );

  const handleLoginToken = useCallback(
    async (token: string) => {
      try {
        setIsLoading(true);

        const { user } = await signInWithCustomToken(auth, token);

        registerMixpanel({
          id: user.uid,
          nome: user.displayName!,
          email: user.email!,
        });

        trackEvent("login.token.sucesso", {
          id: user.uid,
          email: user.email,
          provider: "email",
        });

        updateIsLogged();

        return true;
      } catch (err) {
        trackEvent("login.token.erro", {
          error: error[(err as any).code] ?? error.message ?? null,
        });
        showError(error[(err as any).code] ?? "Não foi possível entrar");
        setIsLoading(false);

        return false;
      }
    },
    [updateIsLogged]
  );

  return {
    handleLoginGoogle,
    handleLoginEmail,
    handleLoginFacebook,
    handleLoginToken,
    isLoading,
  };
}
