import { trackEvent } from "infra/helpers/event";
import create from "zustand";
import { persist } from "zustand/middleware";
import { TenantMeResponse } from "./typings/user";

interface TenantStore {
  tenant: TenantMeResponse;
  selecionar: (tenant: TenantMeResponse) => void;
  reset: () => void;
}

export const useCurrentTenant = create<TenantStore>(
  persist(
    (set) => ({
      tenant: {} as TenantMeResponse,
      selecionar: (tenant: TenantMeResponse) => {
        trackEvent("tenant.selecionado", {
          id: tenant.id,
          nome: tenant.nome,
        });

        set((state) => ({ ...state, tenant }));
      },
      reset: () => {
        set((state) => ({ ...state, tenant: {} as TenantMeResponse }));
      },
    }),
    {
      name: "@administradora-digital-tenant",
      getStorage: () => localStorage,
    }
  )
);
