const b1 = '#E5E5E5'

const blue = '#005081'
const blueDark = '#121528'
const blue100 = '#EFF4FF'

const blue200 = '#E2ECF9'
const blue400 = '#5081FF'
const blue500 = '#388CAE'
const blue300 = '#0081FF'

const cyan = "#22A6F0";
const yellow = "#FDDE7D";

const pink = "#C500F7";
const pink100 = "#f595c6";
const pink200 = "#d83dff";
const pink300 = "#e063ff";
const pink400 = "#e88fff";
const pink500 = "#fdeff6";

const red100 = 'rgba(255, 143, 143, .2)'
const red500 = '#DB8572'
const red = '#EE5322'

const black = '#15181C'
const black900 = '#424447'
const black700 = '#56626E'
const black600 = '#838485'
const black500 = '#B3B4B6'
const black300 = '#C2C2C2'
const black200 = '#DEDFE0'
const black100 = '#F5F4F4'
const black50 = '#e2e8f0'
const blueishGray = '#BAC5DD'
const blueishGray100 = '#BFC8D6'
const blueishGray500 = '#8291A9'
const darkBlueGray = '#525E7C'
const borderGray = '#CCCCCC'
const blueishGray50 = '#B5BECD'
const inputBorderGray = '#B5BECD'

const white = '#FFF'

const green = 'rgba(109, 215, 157, 1)'
const green100 = 'rgba(109, 215, 157, .15)'
const green500 = '#09B66D'
const green1000 = '#38A169'

const orange = '#FF7A00'
const orange100 = '#FFDE9D'
const orange50 = 'rgba(255, 122, 0, .2)'

const purple = "rgba(79, 82, 255, 1)";
const purple50 = "#f2f3fe";
const purple100 = "rgba(79, 82, 255, .2)";
const purple200 = "#ABACF7";

const lightGray = '#ECEFF2';
const darkGray = '#8291A9';

// const purpleDark = "#393bef";

const secondary = '#E36A4D';

const theme = {
  b1,
  secondary,
  royal: blue,
  sky: blue200,
  blonde: yellow,
  coral: red500,
  dark: black,
  carbon: black900,
  steel: black700,
  silver: black600,
  gray: black500,
  lightGray,
  darkGray,
  smoke: black200,
  snow: black100,
  neutral: white,
  primary: blue,
  primaryLight: '#3084A9',
  primaryDark: blueDark,
  error: red,
  errorLight: red100,
  success: green,
  successLight: green100,
  info: blue500,
  infoLight: blue100,
  warning: orange,
  warningLight: orange100,
  blueishGray,
  darkBlueGray
}

const colors = {
  b1,
  purple,
  purple50,
  purple100,
  purple200,

  blue,
  blue100,
  blue200,
  blue300,
  blue400,
  blue500,

  cyan,

  yellow,

  pink,
  pink100,
  pink200,
  pink300,
  pink400,
  pink500,

  red100,
  red500,
  red,

  black,
  black900,
  black700,
  black600,
  black500,
  black300,
  black200,
  black100,
  black50,

  white,

  green,
  green100,
  green500,
  green1000,

  orange,
  orange100,
  orange50,

  blueishGray,
  blueishGray50,
  blueishGray100,
  blueishGray500,

  inputBorderGray,

  borderGray,

  ...theme
}

export default colors
