import http from "infra/http";
import { useMutation } from "react-query";

function useCheckin() {
  return useMutation((id: string) =>
    http.post(`/mobile/v1/votacao/${id}/checkin`)
  );
}

export default useCheckin;
