import axios from "infra/http";
import { useMutation } from "react-query";

function useVotar() {
  return useMutation(
    (voto: { id: string; perguntaId: string; opcoes: string[] }) =>
      axios.post(`/mobile/v1/votacao/${voto.id}/pergunta/${voto.perguntaId}`, {
        opcoes: voto.opcoes,
      })
  );
}

export default useVotar;
