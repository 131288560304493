import history from "infra/helpers/history";
import { Menu } from "./typings/menu";
import { FaUsers } from "react-icons/fa";
import colors from "core/resources/theme/colors";
import { checkPermission } from "infra/helpers/permission";
import { PermissaoEnum } from "core/resources/enums/permissao";

const menu = (): Menu[] => {
  return [
    {
      title: "Inicio",
      icon: <i className="mdi mdi-home" />,
      visible: () => true,
      action: () => history.push("/"),
      children: [],
    },
    {
      title: "Votação",
      visible: () => true,
      icon: <FaUsers fontSize={18} />,
      children: [],
      bg: colors.purple100,
      color: colors.purple,
      action: () => history.push("/assembleias"),
    },
    {
      title: "Boletos",
      visible: () => checkPermission(PermissaoEnum.App.Boleto.Visualizar),
      icon: <FaUsers fontSize={18} />,
      children: [],
      bg: colors.purple100,
      color: colors.purple,
      action: () => history.push("/boletos"),
    },
  ];
};

export function useMenu() {
  return {
    menu,
  };
}
