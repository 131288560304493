import axios from "infra/http";
// import { ZoomMtg } from "@zoomus/websdk";

export async function generateSignature(
  webinarId: string
): Promise<{ signature: string; apiKey: string }> {
  const { data } = await axios.get<{ signature: string; apiKey: string }>(
    `/v1/zoom-webinar/${webinarId}/signature`
  );

  return data;
}

// export function createInstance() {
//   ZoomMtg.setZoomJSLib("https://source.zoom.us/2.3.5/lib", "/av");

//   ZoomMtg.preLoadWasm();
//   ZoomMtg.prepareWebSDK();
//   // loads language files, also passes any error messages to the ui
//   ZoomMtg.i18n.load("pt-PT");
//   ZoomMtg.i18n.reload("pt-PT");
// }
